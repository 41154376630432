import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ProjectPage = ({ data }) => {
  const project = data.allProjectsJson.nodes[0]

  let nextProject = ""
  let nextProjectName = ""

  if (project.slug === "/lhd-car-showroom") {
    nextProject = "/so-cute-nails"
    nextProjectName = "So Cute Nails"
  }

  if (project.slug === "/so-cute-nails") {
    nextProject = "/stitch"
    nextProjectName = "Stitch Group"
  }

  if (project.slug === "/stitch") {
    nextProject = "/1x2-network"
    nextProjectName = "1X2 Network"
  }

  if (project.slug === "/1x2-network") {
    nextProject = "/vector-logos"
    nextProjectName = "Vector Logos"
  }

  if (project.slug === "/vector-logos") {
    nextProject = "/illustrations"
    nextProjectName = "Illustrations"
  }

  if (project.slug === "/illustrations") {
    nextProject = "/lhd-car-showroom"
    nextProjectName = "LHD car showroom"
  }

  return (
    <Layout>
      <div className="container mx-auto p-2 sm:p-3 md:p-0">
        <div className="mb-28">
          <img
            style={{ width: "100%", maxWidth: "700px", margin: "40px auto" }}
            src={project.heroImage.src.publicURL}
            alt={project.title}
          />
        </div>
        <h2 className="text-5xl text-green capitalize font-bold pb-6">
          {project.title}
        </h2>
        <h3 className="text-2xl text-black font-bold pb-6">
          {project.heading}
        </h3>
        <p className="pb-6">
          {" "}
          <div dangerouslySetInnerHTML={{ __html: project.text }} />{" "}
        </p>

        <div>
          {project.gallery.map(g => (
            <div
              className={
                project.slug === "/vector-logos" ? "container mx-auto" : ""
              }
            >
              <h3 className="text-2xl text-green font-bold pb-6">
                {" "}
                <div dangerouslySetInnerHTML={{ __html: g.title }} />{" "}
              </h3>

              <img
                style={{
                  maxWidth: `${
                    project.slug === "/illustrations" ||
                    project.slug === "/vector-logos"
                      ? "700px"
                      : "initial"
                  }`,
                  width: "100%",
                  height: "auto",
                  margin: "30px auto",
                }}
                src={g.img.publicURL}
                alt={g.title}
              />
            </div>
          ))}
        </div>

        <Link
          className="block text-7xl uppercase text-green font-bold no-underline text-center group-hover:opacity-100 group"
          to={nextProject}
          style={{ marginBottom: "50px", marginTop: "50px" }}
        >
          Next{" "}
          <span className="capitalize block -bottom-2 text-2xl font-thin text-black opacity-0 group-hover:opacity-100 group-hover:bottom-0 ease-in-out relative duration-200">
            {nextProjectName}
          </span>
        </Link>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Projects | Rose Gracia" />

export const query = graphql`
  query ($slug: String!) {
    allProjectsJson(filter: { slug: { eq: $slug } }) {
      nodes {
        heroImage {
          src {
            publicURL
          }
        }
        gallery {
          title
          img {
            publicURL
          }
        }
        position
        slug
        text
        title
        heading
      }
    }
  }
`

export default ProjectPage
